import { createSlice } from '@reduxjs/toolkit'

const eventSlice = createSlice({
    name: 'events',
    initialState: { event: null,pageNumber: 1 },
    reducers: {
        searchEvent: (state, action) => {
            //const { token } = action.payload
            state.event = action.payload
        },
        clearEvent: (state, action) => {
            state.event = null
        },
        changePage: (state, action) => {
            state.pageNumber = action.payload
        },
    }
})

export const { searchEvent, clearEvent,changePage } = eventSlice.actions

export default eventSlice.reducer
