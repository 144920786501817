import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Loader from '..//../components/Loader'

const Index = () => {
  const { t, i18n } = useTranslation();
  const [loading,setLoading] = useState(true);

  useEffect(()=> {
    setTimeout(()=> {
      setLoading(false)
    },300)
  },[])
  if (loading) {
    return <Loader />
  }
  return (
    <div className='mainContainer'>
      <div className='innerBanner'>
        <img src={require('../../assets/images/banner/banner-1.JPG')} alt="Event" />
      <div className='innerBannerWrap non-event'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='banner-item-wrap'>
                <h1 className=''></h1>
                
                <p className='h3'><em>"ब्रह्म ज्ञान की धर्म ध्वजा को जग में है लहराना"</em></p>
              
                <h1 className=''>&nbsp;</h1>
                <p className='h3'><em>" We have to hoist the religious flag of Brahma Gyan in the world "</em></p>
                
                <p className='h5'>~ Dheer Singh</p>
              </div> 
            </div>
            <div className='col-md-6'>
              <div className='banner-item-wrap'>
                
              </div> 
            </div>
          </div>          
        </div>
      </div>
    </div>
    <Container>
    <p>Home / about</p>
      <Row className="justify-content-md-center">
        <Col xs={12} md={12}>
         <h1 className='site-color-dark-red text-center mb-5 mt-5'>{t('aboutUs.title')}</h1>
        </Col>
        <Col xs={12} md={7}>
          <div className='about-content'>
            {/* <div dangerouslySetInnerHTML={{ __html: t('aboutUs.content') }}></div> */}
            <p>Welcome to <em>Nirakar Satguru</em>, a dedicated spiritual community committed to fostering inner peace, growth, and enlightenment. Our organization serves as a sanctuary for individuals seeking spiritual guidance, wisdom, and a deeper connection to their inner selves.</p>
            <p>At <em>Nirakar Satguru</em>, we believe in the transformative power of spirituality and aim to create a supportive environment where seekers from all walks of life can explore and nurture their spiritual journey.</p>
            <br />
            <h4>Our Mission</h4>
            <p>At Nirakar Satguru, our mission is to create an inclusive and nurturing environment where individuals from all walks of life can explore their faith, find solace, and cultivate a deeper connection with the divine. We are committed to:</p>

            <ul>
              <li><strong>Facilitating Spiritual Growth: </strong>We strive to facilitate personal and spiritual growth by offering teachings, practices, and resources that inspire self-discovery and inner transformation</li>

              <li><strong>Creating Community: </strong>We aim to build a community of like-minded individuals who support and uplift each other on their spiritual paths. Through group activities, discussions, and events, we foster connections and a sense of belonging.</li>
              <li><strong>Sharing Wisdom: </strong>Our organization is committed to sharing ancient wisdom, spiritual teachings, and practices that encourage mindfulness, compassion, and a deeper understanding of life's mysteries.</li>
              <li><strong>Guiding towards Inner Peace: </strong>We provide guidance, tools, and practices that help individuals achieve inner peace, balance, and harmony in their lives amidst the challenges of the modern world.</li>
              <li><strong>Service and Outreach:</strong> Embracing the principle of selfless service (seva) by actively engaging in charitable initiatives, volunteering, and contributing positively to society.</li>
            </ul>

            <p>Whether you're just beginning your spiritual journey or seeking to deepen your spiritual practice, <em>Nirakar Satguru</em> offers a welcoming space for your exploration. Join us as we embark on a journey of self-discovery and spiritual awakening together.</p>
          </div>
        </Col>
        <Col xs={12} md={5}>
          <div className='about-image'>
          
            <img className='full-width' alt='image' src={require('../../assets/images/about.jpg')} />
          
          </div>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Index
