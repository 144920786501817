import { useGetEventsQuery } from "./eventsApiSlice"
import Event from "./Event"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import { Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"


const EventList = () => {
    useTitle('Nirakar Satgur: Events List')

    const { id, isManager, isAdmin } = useAuth()
    const {pageNumber} = useSelector((state)=> state?.event)
    const {
        data: events,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetEventsQuery(pageNumber,{
   
        refetchOnFocus: false,
        refetchOnMountOrArgChange: true
    });
    

    let content

    if (isLoading) content = <Spinner color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = events

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(eventId => entities[eventId].createdBy === id)
        }

        const tableContent = ids?.length && filteredIds.map((eventId,index) => <Event key={eventId} eventId={eventId} index={index} />)

        content = (
            <>
            <div className="flex justify-between ">
                <h2 className="pageHeading">Event List</h2>
                <Link className="btn btn-primary mb-20" to='/dashboard/events/new'>Add New Event</Link>
            </div>
            
            <table className="table table--notes">
                <thead className="table__thead">
                    <tr>
                        <th scope="col" className="table__th note__status">S.No.</th>
                        <th scope="col" className="table__th note__status">Name</th>
                        <th scope="col" className="table__th note__created">Oraganizer</th>
                        <th scope="col" className="table__th note__created">Date</th>
                        <th scope="col" className="table__th note__updated">Time</th>
                        <th scope="col" className="table__th note__title">Place</th>
                        <th scope="col" className="table__th note__username">Banner</th>
                        <th scope="col" className="table__th note__edit">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table></>
        )
    }

    return content
}
export default EventList