import React,{useState,useEffect} from 'react'
//import '../../dashboard.css'; // Import your custom CSS
import { useNavigate } from 'react-router-dom';

import { useAddNewBhajanMutation } from './bhajansApiSlice';
import { Spinner,Form } from 'react-bootstrap';

const NewBhajan = () => {
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    content: '',
    audio: '',
    errors:{}
  });
  const [created,setCreated] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };
  const handleFileChange = (event) => {
    const { name, value } = event.target;
    setFormData({
        ...formData,
        [name]: value,
        errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
      });
    setSelectedFile(event.target.files[0]);
  };
  const [addNewBhajan, {
    isLoading,
    isSuccess,
    isError,
    error
}] = useAddNewBhajanMutation()

const navigate = useNavigate()


useEffect(() => {
    if (isSuccess) {
        navigate('/dashboard/bhajans')
    }
}, [isSuccess, navigate])
const canSave = !isLoading

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    //setCreated(true)
    // Handle form submission logic
    const { title, author,content} = formData;
    const errors = {};

    // Validation rules
    if (!title.trim()) {
      errors.title = 'Title is required';
    }
    if (!author.trim()) {
      errors.author = 'Author is required';
    }
    if (!content.trim()) {
      errors.content = 'Content is required';
    }
    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }

    const newFomData = new FormData();
    if (selectedFile) {
        newFomData.append('file', selectedFile);
    }
    
    newFomData.append('title',title );
    newFomData.append('author',author );
    newFomData.append('content',content );
    console.log(newFomData,newFomData.get('file'))
    if(canSave) {
        await addNewBhajan(newFomData);
    }
   
    
  };

  const { title, author,content,errors} = formData;
  const errClass = isError ? "errmsg" : "offscreen"
  return (
    <>
            <h2 className="pageHeading">Create Bhajan</h2>
            <p className={errClass}>{error?.data?.message}</p>
       
              <div className='formWrap'>
              <form className='' onSubmit={handleFormSubmit}>
                <div className='formGroup'>
                  <label>Title:</label>
                  <input type='text' name='title' placeholder='Enter Bhajan titel!' value={formData.title} onChange={handleInputChange} />
                </div>
                {errors.title && <span className="error">{errors.title}</span>}
                <div className='formGroup'>
                  <label>Author:</label>
                  {/* <input type='text' name='author' value={formData.author} readOnly  /> */}
                  <Form.Select aria-label="Default select example" name="author" value={formData.author} onChange={handleInputChange}>
                      <option value=''>Select Author</option>
                      <option value="Dheer Singh धीर सिंह">Dheer Singh</option>
                      <option value="Mangal Singh मंगल सिंह">Mangal Singh</option>
                      <option value="Triloki Nath Bajpai त्रिलोकी नाथ बाजपेयी">Triloki Nath Bajpai</option>
                    </Form.Select>
                </div>
                {errors.author && <span className="error">{errors.author}</span>}
                <div className='formGroup'>
                  <label>Audio:</label>
                  <input type='file' name='audio' value={formData.audio} onChange={handleFileChange} placeholder='Enter Bhajan author!' />
                </div>
                {errors.audio && <span className="error">{errors.audio}</span>}
                <div className='formGroup'>
                  <label>Content:</label>
                  <textarea  name='content' placeholder='Enter Bhajan' onChange={handleInputChange} className='long-content'>
                    {formData.content}
                  </textarea>
                </div>
                {errors.content && <span className="error">{errors.content}</span>}
                <div className='submit'>
                  <button type='submit' className='btn btn-primary'>
                    Create New {isLoading?(<Spinner />):null}
                  </button>

                </div>
              </form>
            </div>
          
            
            
         
        
    </>
  )
}

export default NewBhajan