import React from 'react'

const BannerInner = ({item}) => {
  console.log('Banner item',item)
  return (
    <div className='innerBanner'>
        <img src={require('../../assets/images/banner/inner-banner.png')} alt="Event" />
      <div className='innerBannerWrap'>
        <div className='container'>
          <div className='banner-item-wrap'>
              <h1 className=''>{item.name}</h1>
              <p>Organizer: {item.organizer}</p>
              <p>Date: {item.date}</p>
              <p>Time: {item.time}</p>
              <p>Place: {item.place}</p>
              <p>Event Type: {item.category}</p>
          </div>           
        </div>
      </div>
    </div>
  )
}

export default BannerInner
