import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './banner.css';

const index = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5,

        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };


  return (
    <Carousel responsive={responsive} showDots={true}>
        <div className='banner-items'>
          <img alt='image' src={require('../../assets/images/banner/slider3.jpg')} />
          <div className='banner-content'>
            <div className='wrapper'>
                <div className='event-details'>
                  <h2>
                    
                  </h2>
                  <p>
                  करा दे आत्म दर्शन जो उसे भगवान कहते हैं।<br/>
                  लखा दे हरी जो कण-कण में उससे गुरु ज्ञान कहते हैं।।

                  </p>
                </div>
            </div>
          </div>
        </div>

        <div className='banner-items'><img alt='image' src={require('../../assets/images/banner/slider2.JPG')} />
        <section className='banner-content'>
        <div className='wrapper'>
            <div className='event-details'>
              <h2>
                Guru purnima
              </h2>
              <p>
                happy guru purnima
              </p>
            </div>
        </div>
      </section>
        </div>
        
    </Carousel>
    
  )
}

export default index