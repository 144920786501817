import React,{useState,useEffect} from 'react'
//import '../../dashboard.css'; // Import your custom CSS
import { useNavigate, useParams } from 'react-router-dom';

import {  useGetBhajansQuery, useUpdateBhajanMutation } from './bhajansApiSlice';
import { Spinner } from 'react-bootstrap';
import useTitle from '../../hooks/useTitle';
import useAuth from '../../hooks/useAuth';

const EditBhajan = ({}) => {
    useTitle('Nirakar Satguru: Edit Bhajan')

    const { id } = useParams()
    console.log('params id',id)
    const { isManager, isAdmin } = useAuth()

    const { bhajan } = useGetBhajansQuery('bhajanList', {
        selectFromResult: ({ data }) => ({
            bhajan: data?.entities[id]
        }),
    })
    console.log('...',bhajan)
    
  const [formData, setFormData] = useState({
    title: bhajan?.title,
    author: bhajan?.author,
    content: bhajan?.content,
    errors:{}
  });
  const [created,setCreated] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };
  const [updateBhajan, {
    isLoading,
    isSuccess,
    isError,
    error
}] = useUpdateBhajanMutation()

const navigate = useNavigate()


useEffect(() => {
    if (isSuccess) {
        navigate('/dashboard/bhajans')
    }
}, [isSuccess, navigate])

  const handleFormSubmit = async(e) => {
    e.preventDefault();
    //setCreated(true)
    // Handle form submission logic
    const { title, author,content} = formData;
    const errors = {};

    // Validation rules
    if (!title.trim()) {
      errors.title = 'Title is required';
    }
    if (!author.trim()) {
      errors.author = 'Author is required';
    }
    if (!content.trim()) {
      errors.content = 'Content is required';
    }
    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }
    updateBhajan({id,...formData});
    
  };

  const { title, author,content,errors} = formData;
  const errClass = isError ? "errmsg" : "offscreen"
  return (
    <>
            <h2 className="pageHeading">Create Bhajan</h2>
            <p className={errClass}>{error?.data?.message}</p>
              <div className='formWrap'>
              <form className='' onSubmit={handleFormSubmit}>
                <div className='formGroup'>
                  <label>Title:</label>
                  <input type='text' name='title' placeholder='Enter Bhajan titel!' value={formData.title} onChange={handleInputChange} />
                </div>
                {errors.title && <span className="error">{errors.title}</span>}
                <div className='formGroup'>
                  <label>Author:</label>
                  <input type='text' name='author' value={formData.author} onChange={handleInputChange} placeholder='Enter Bhajan author!' />
                </div>
                {errors.author && <span className="error">{errors.author}</span>}
                <div className='formGroup'>
                  <label>Content:</label>
                  <textarea  name='content' placeholder='Enter Bhajan' onChange={handleInputChange} className='long-content'>
                    {formData.content}
                  </textarea>
                </div>
                {errors.content && <span className="error">{errors.content}</span>}
                <div className='submit'>
                  <button type='submit' className='btn btn-primary'>
                    Save {isLoading?(<Spinner />):null}
                  </button>

                </div>
              </form>
            </div>
           
            
            
         
        
    </>
  )
}

export default EditBhajan