import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { Container, Form, Button, Row,  Spinner } from 'react-bootstrap';
import { useAddNewEventMutation } from "./eventsApiSlice"

const NewEventForm = () => {

    const [addNewEvent, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewEventMutation()

    const navigate = useNavigate()


    useEffect(() => {
        if (isSuccess) {
            navigate('/dashboard/events')
        }
    }, [isSuccess, navigate])

   useEffect(()=> {
    console.log(error)
   },[error])

    const canSave = !isLoading

    const onSaveEventSubmmit = async () => {
      
        if (canSave) {
            await addNewEvent(eventData)
        }
    }
    const [success, setSuccess] = useState(false)
  const [err, setErr] = useState({ error: false, message: null });
  const [submitting, setSubmitting] = useState(false)
  const [eventData, setEventData] = useState({
    name: '',
    date: '',
    time: '',
    banner:'',
    duration: '',
    organizer: '',
    place: '',
    category: '', // Define initial category (Daily, Weekly, Monthly, Yearly) as needed
    errors: {}
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
      errors: { ...eventData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const { name, value } = event.target;
    setEventData({
      ...eventData,
      [name]: value,
      errors: { ...eventData.errors, [name]: '' }, // Clear previous errors on input change
    });
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    // Perform API call to send event data to the backend for creation
    // Use fetch or Axios to make API requests
    const { name, date, organizer, place, time, category ,banner} = eventData;
    const errors = {};
    
   

    // Validation rules
    if (!name.trim()) {
      errors.name = 'Event Name is required';
    }
    if (!organizer.trim()) {
      errors.organizer = 'Organize Name is required';
    }
    if (!date.trim()) {
      errors.date = 'Event Date is required';
    }
    if (!time.trim()) {
      errors.time = 'Event time is required';
    }
    if (!category.trim()) {
      errors.category = 'Event type is required';
    }
    if (!place.trim()) {
      errors.place = 'Event place is required';
    }

    // if (!selectedFile) {
    //   errors.banner = 'Event banner is required';
    //   console.log('error',errors.banner)
    //  // return;
    // }
    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setEventData({ ...eventData, errors });
      return;
    }

    

    const formData = new FormData();
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
    
    formData.append('name', name);
    formData.append('date',date);
    formData.append('time',time);
    formData.append('place',place);
    formData.append('organizer',organizer);
    formData.append('category',category);
    formData.append('banner',banner)
    console.log(formData.get('file'),formData)
     if (canSave) {
      await addNewEvent(formData)
    }
    

  };

  const { errors } = eventData

   

    const errClass = isError ? "errmsg" : "offscreen"

    const content = (
        <>
            <h2 className="pageHeading">Create New Event</h2>
            <p className={errClass}>{error?.data?.message}</p>

            
            <Container>
              <Row className="justify-content-md-center">

                <Form onSubmit={handleSubmit}>
                  {/* Input fields for event creation */}
                  {/* Replace these with appropriate form controls */}
                  <div className='formGroup'>
                    <label>Event Name<sup>*</sup>:</label>
                    <input type='text' name='name' placeholder='Enter Event Name' value={eventData.name} onChange={handleInputChange} />
                    {errors.name && <span className="error">{errors.name}</span>}
                  </div>

                  <div className='formGroup'>
                    <label>Event Oraganizer<sup>*</sup>:</label>
                    <input type='text' name='organizer' value={eventData.organizer} onChange={handleInputChange} placeholder='Enter Organize' />
                    {errors.organizer && <span className="error">{errors.organizer}</span>}
                  </div>
                  <div className='flex gap-4'>
                  <div className='formGroup'>
                    <label>Event Date<sup>*</sup>:</label>
                    <input type='date' name='date' value={eventData.date} onChange={handleInputChange} placeholder='Enter date' />
                    {errors.date && <span className="error">{errors.date}</span>}
                  </div>
                  <div className='formGroup'>
                    <label>Event Time<sup>*</sup>:</label>
                    <input type='time' name='time' value={eventData.time} onChange={handleInputChange} placeholder='Enter time' />
                    {errors.time && <span className="error">{errors.time}</span>}
                  </div>
                  </div>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Event Banner:</Form.Label>
                    <Form.Control type="file" name='banner' value={eventData.banner}  accept="image/*" onChange={handleFileChange} />
                    {errors.banner && <span className="error">{errors.banner}</span>}
                  </Form.Group>
                  <div className='formGroup'>
                    <label>Event Category<sup>*</sup>:</label>
                    <Form.Select aria-label="Default select example" name="category" value={eventData.category} onChange={handleInputChange}>
                      <option value=''>Event Type</option>
                      <option value="Daily">Daily Event</option>
                      <option value="Weekly">Weekly Event</option>
                      <option value="Monthly">Monthly Event</option>
                      <option value="Yearly">Yearly Event</option>
                    </Form.Select>
                    {errors.category && <span className="error">{errors.category}</span>}
                  </div>

                  <div className='formGroup'>
                    <label>Event Place<sup>*</sup>:</label>
                    <textarea name='place' placeholder='Enter Full address of the event ' value={eventData.place} onChange={handleInputChange} className='long-content'>

                    </textarea>
                    {errors.place && <span className="error">{errors.place}</span>}
                  </div>




                  {/* Add other input fields for date, time, duration, organizer, place, category */}

                  <Button variant="primary" type="submit">
                    Create Event {isLoading ? (<Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />) : null}
                  </Button>
                </Form>
              </Row>
            </Container>
        </>
    )

    return content
}

export default NewEventForm