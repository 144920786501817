// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  //isAuthenticated: !!Cookies.get('authToken'), // Check if the token exists
  isAuthenticated: false,
  token: null,
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCredential: (state,action)=> {
      const {user,token} = action.payload
      state.user = user
      state.token = token
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = action.payload.token;
      Cookies.set('authToken', action.payload.token); // Set the token in a cookie
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
     // console.log('authSlice js ',action.payload)
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      Cookies.remove('authToken'); // Remove the token from the cookie on logout
      state.token = null
    },
  },
});

export const { loginStart, loginSuccess, loginFailure, logout } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state)=>state.auth.user
export const selectCurrentToken = (state)=>state.auth.token