import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
//import "./login.css";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link,useParams } from 'react-router-dom';
import { resetPassword } from "../../api";


const ResetPassword = () => {
    const {token} = useParams()
    console.log('params:',token)
  const navigate = useNavigate();
  const { isAuthenticated, error, loading } = useSelector((state) => state.auth); // Get authentication state
  const [err, setErr] = useState({ error: false, message: null });
  const [submitting,setSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    password: '',
    repassword: '',
    errors: {}
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };


  const handleForgotPassword = () => {
    // Add your "Forgot Password" logic here
  };




  const subminHandler = async (e) => {
    e.preventDefault();

    const { repassword, password } = formData;
    const errors = {};

    // Validation rules
    if (!password) {
        errors.password = 'Password is required';
      } else if (password.length < 8) {
        errors.password = 'Password must be at least 8 characters';
      }
  
      if (password !== repassword) {
        errors.repassword = 'Passwords do not match';
      }


    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }
    setSubmitting(true)
    //console.log('from login page',formData)
    let response = await resetPassword({...formData,token})
    if (!response.success) {
      setSubmitting(false);
      // console.log('response error', response)
      setErr({ responseerror: response.message })
      return
    }
    console.log('signup success response', response)
    setSubmitting(false)
    setFormData({
        password: '',
        repassword: '',
        errors: {}
      })
    navigate('/login')
  }

  const {  errors } = formData;
  return (
    <div className="auth-container-new mt-5">


      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={4}>
            <Card className="mt-5 shadow">
              <Card.Body>
                <Card.Title className="text-center site-color-dark-red h2 mb-4"></Card.Title>
                <Form onSubmit={subminHandler}>
                  <Form.Group controlId="formBasicEmail" className="form-group">
                    <FontAwesomeIcon icon={faLock} className="form-icon" />
                    <Form.Control type="text" placeholder="New Password" name="password" value={formData.password}
                      onChange={handleInputChange} />

                  </Form.Group>
                  {errors.password && <span className="error">{errors.password}</span>}
                  <Form.Group controlId="formBasicPassword" className="form-group">
                    <FontAwesomeIcon icon={faLock} className="form-icon" />
                    <Form.Control type="password" name="repassword" placeholder="Re Enter Password" value={formData.repassword}
                      onChange={handleInputChange} />

                  </Form.Group>
                  {errors.repassword && <span className="error">{errors.repassword}</span>}
                  <div className="form-group">
                    <Button variant="primary" type="submit" className="form-btn">
                      Reset Password {submitting ? (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : null}
                    </Button>
                  </div>


                </Form>
                
                
                {error != null ? (<p className="error text-center">{error}</p>) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default ResetPassword;

