import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare ,faTrashCan} from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetEventsQuery,useDeleteEventMutation } from './eventsApiSlice'
import { memo } from 'react'

const Event = ({ eventId,index }) => {

    const { event } = useGetEventsQuery('eventList',{
        selectFromResult: ({ data }) => ({
            event: data?.entities[eventId]
        }),
    })
    const [deleteEvent, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteEventMutation()

    const onDeleteEventClicked = async (eventId) => {
        await deleteEvent({ id: eventId })
    }
    const navigate = useNavigate()

    if (event) {
        const created = new Date(event.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const date = new Date(event.date).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dashboard/events/${eventId}`)

        return (
            <>
            <tr className="table__row">
            <td className="table__cell note__created">{index+1}</td>
                <td className="table__cell note__status">
                    <span className="note__status--completed">{event.name}</span>
                       
                </td>
                <td className="table__cell note__created">{event.organizer}</td>
                <td className="table__cell note__created">{date}</td>
                <td className="table__cell note__updated">{event.time}</td>
                <td className="table__cell note__title">{event.place}</td>
                <td className="table__cell note__username">{event.banner}</td>

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                        className="icon-button table__button"
                        onClick={()=>onDeleteEventClicked(event.id)}
                    >
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </td>
            </tr>
            {isDelError?(<tr>
                <td colSpan={7}>{isDelError}</td>
            </tr>):null}
            </>
        )

    } else return null
}

const memoizedEvent = memo(Event)

export default memoizedEvent