import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { Link } from 'react-router-dom';
import { listBhajan,searchBhajan } from '../../api'
import Loader from '../../components/Loader'


const Index = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [formData, setFormData] = useState({
        title: '',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Update currentPage based on user interaction
    };

    const searchItems = (e)=> {
        e.preventDefault()
        console.log(formData.title)
        searchData(formData.title)
    }

    const searchData = async (title)=> {
        try {
            const response = await searchBhajan(title); // Replace with your API endpoint
            if (response.success) {

                setItems(response.result); // Set the fetched items in state
                setTotalPages(response.totalPages); // Assuming total pages is sent from the server
                setLoading(false);
                console.log(response)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        // Function to fetch data from the API endpoint
        const fetchData = async () => {
            try {
                let params = {
                    page: currentPage, // Specify the page number
                    limit: 5, // Specify the limit per page
                }
                const response = await listBhajan(params); // Replace with your API endpoint
                if (response.success) {

                    setItems(response.result); // Set the fetched items in state
                    setTotalPages(response.totalPages); // Assuming total pages is sent from the server
                    setLoading(false);
                    console.log(response)
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData(); // Call the function to fetch data when the component mounts

        

    }, [currentPage]); // The empty dependency array ensures it only runs once
    if(loading) {
        return <Loader />
    }
    return (
        <>
            
            <div className='inner-wrapper'>
                <div className='container'>
                    <div className='top-heading flex justify-between'>
                        <h1 className='site-color-dark-red'>Bhajans</h1>
                        <div className='search-wrap'>
                            <form onSubmit={searchItems}>
                                <div className='flex search-box'>
                                    <input type='text' placeholder='Search Bahajan' className='' name="title" onChange={handleInputChange} value={formData.title}  />
                                    <FontAwesomeIcon icon={faSearch}  size='xs'/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='listWrap'>
                        {loading?(
                            <div className='loadering-box'><div className='loader-18'></div></div>
                        ):(
                            <ul className='item-list linked'>
                            {items && items.map((item, index) => (
                                <li key={index}><Link to={`/bhajans/${item._id}`}>{item.title}</Link></li>

                            ))}
                        </ul>
                        )}
                        
                    </div>
                    <div className='pagingwrap'>
                        <nav>
                            <ul className="pagination">
                                <li className="page-item"><button className='page-link'
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button></li>
                                <li className="page-item"><span className='page-link'>Page {currentPage} of {totalPages}</span></li>
                                <li className="page-item"><button className='page-link'
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button></li>
                            </ul>
                        </nav>
                        {/* Pagination controls */}



                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Index