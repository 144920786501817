import React, { Suspense } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Assuming you use React Router


const NotFound = () => {
  const { t, i18n } = useTranslation();

  const  changeLanguage= (lng) =>{
    console.log('Changing language to:', lng);
  i18n.changeLanguage(lng);
  console.log('Language changed to:', i18n.language);
  }
  const Loader = ()=> {
    return <>Loadding...</>
  }
  return (
    <Suspense fallback={<Loader />}>
    <Container >
        <div className='mainContainer'>
      <Row className="mt-5">
        <Col className="text-center">
          <h1 className='site-color-dark-red'>404 - Not Found</h1>
          <p>
            Sorry, the page you are looking for does not exist or may have been moved.
          </p>
          <p>
            Please click the button below to go back to the homepage:
          </p>
          <Link to="/">
            <Button variant="primary">Go to Homepage</Button>
          </Link>
          <h1>{t('welcome')}</h1>
      <p>{t('about')}</p>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('hi')}>Hindi</button>
        </Col>
      </Row>
      </div>
    </Container>
    </Suspense>
  );
};

export default NotFound;
