import React from 'react'
import Header from '../../components/Header'

const index = () => {
  return (
    <div className='inner-wrapper'>
    
      <h1>Gallery</h1>
    </div>
  )
}

export default index
