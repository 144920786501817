import { Outlet } from 'react-router-dom'
import LeftNavigation from './LeftNavigation'
import  './dashboard.css'

const Dashboard = () => {
 
  return (
    <>
    <div className='topBar'></div>
      <div className="contentBar">
        <div className="lefPanel">
         <LeftNavigation />
        </div>

        <div className="rightPanel">
          <div className="contentBox">
           <Outlet />
          </div>
         </div>
      </div>
    </>
  )
}

export default Dashboard