import NewEventForm from './NewEventForm'


import useTitle from '../../hooks/useTitle'


const NewEvent = () => {
    useTitle('Nirakar Satguru: New Event')

   

    const content = <NewEventForm  />

    return content
}
export default NewEvent