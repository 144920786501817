import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const bhajansAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = bhajansAdapter.getInitialState()

export const bhajansApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBhajans: builder.query({
            query: (pageNumber) => ({
                url: `/api/v1/all-bhajans?page=${pageNumber}`,
                method:'GET',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                console.log('bhajans=>',responseData.result)
                let data = responseData.result
                const loadedBhajans = data.map(bhajan => {
                    bhajan.id = bhajan._id
                    return bhajan
                });
                return bhajansAdapter.setAll(initialState, loadedBhajans)
            },
            providesTags: (result, error, arg) => {
                console.log('<==>',result)
                if (result?.ids) {
                    return [
                        { type: 'Bhajan', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Bhajan', id }))
                    ]
                } else return [{ type: 'Bhajan', id: 'LIST' }]
            }
        }),
        addNewBhajan: builder.mutation({
            query: initialBhajan => ({
                url: '/api/v1/bhajans/create-bhajan',
                method: 'POST',
                body: initialBhajan,
                formData: true,
            }),
            invalidatesTags: [
                { type: 'Bhajan', id: "LIST" }
            ]
        }),
        updateBhajan: builder.mutation({
            query: initialBhajan => ({
                url: '/api/v1/bhajans/update',
                method: 'PATCH',
                body: {
                    ...initialBhajan,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Bhajan', id: arg.id }
            ]
        }),
        deleteBhajan: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/bhajans/delete`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Bhajan', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetBhajansQuery,
    useAddNewBhajanMutation,
    useUpdateBhajanMutation,
    useDeleteBhajanMutation,
} = bhajansApiSlice

// returns the query result object
export const selectBhajansResult = bhajansApiSlice.endpoints.getBhajans.select()

// creates memoized selector
const selectBhajansData = createSelector(
    selectBhajansResult,
    bhajansResult => bhajansResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllBhajans,
    selectById: selectBhajanById,
    selectIds: selectBhajanIds
    // Pass in a selector that returns the notes slice of state
} = bhajansAdapter.getSelectors(state => selectBhajansData(state) ?? initialState)