import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../../actions/authActions';
import { signIn, signUp } from '../../api';

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false)
  const [resErr, setReserr] = useState({
    responseerror: ''
  })
  let initialData = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    errors: {},
  }
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    //console.log('from login page',formData);
    
    const { name, email, password, confirmPassword } = formData;
    const errors = {};

    // Validation rules
    if (!name.trim()) {
      errors.name = 'Name is required';
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }

    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }
    // set submmiting true
    setSubmitting(true)
    // If validation passes, proceed with registration logic
    // Example: Send registration data to server
    let response = await signUp(formData)
    if (!response.success) {
      setSubmitting(false);
      // console.log('response error', response)
      setReserr({ responseerror: response.message })
      return
    }
    console.log('signup success response', response)
    setSubmitting(false)
    setFormData(initialData)
    navigate('/verifyregistration', { state: response.verifyUserToken })

  }
  const { name, email, password, confirmPassword, errors } = formData;
  return (
    <div className="auth-container-new mt-5">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={4}>
            <Card className="mt-5">
              <Card.Body>
                <Card.Title className="text-center site-color-dark-red h2 mb-4">Sign Up</Card.Title>
                <Form onSubmit={handleRegistration}>
                  <Form.Group controlId="formBasicName" className="form-group">
                    <FontAwesomeIcon icon={faUser} className="form-icon" />
                    <Form.Control type="text" placeholder="Full Name" name="name" value={formData.name}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.name && <span className="error">{errors.name}</span>}
                  <Form.Group controlId="formBasicEmail" className="form-group">
                    <FontAwesomeIcon icon={faEnvelope} className="form-icon" />
                    <Form.Control type="email" placeholder="Email address" name="email" value={formData.email}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.email && <span className="error">{errors.email}</span>}
                  <Form.Group controlId="formBasicPassword" className="form-group">
                    <FontAwesomeIcon icon={faLock} className="form-icon" />
                    <Form.Control type="password" placeholder="Password" name="password" value={formData.password}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.password && <span className="error">{errors.password}</span>}
                  <Form.Group controlId="formBasicConfirmPassword" className="form-group">
                    <FontAwesomeIcon icon={faLock} className="form-icon" />
                    <Form.Control type="password" placeholder="Re-enter Password" name="confirmPassword" value={formData.confirmPassword}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                  <div className="form-group">
                    <Button variant="primary" type="submit" className="form-btn" >
                      Sign Up {submitting ? (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : null}
                    </Button>
                  </div>
                </Form>

                <div className="text-center mt-3">
                  <p>Already have an account? <Link to="/login">Login</Link></p>
                </div>
                {resErr.responseerror && <div className='text-center'><span className="error">{resErr.responseerror}</span></div>}

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegistrationForm;