import React , {useState}from 'react';
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate,Link, useParams, useLocation } from 'react-router-dom';
import { loginUser } from '../../actions/authActions';
import { signIn, verifySignup } from '../../api';
import { loginSuccess } from '../../store/authSlice';

const VerifyOtpSuccess = () => {
  const location = useLocation();
  const data = location.state;
  
  return (
    <div className="auth-container-new mt-5">
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={4}>
          <Card className="mt-5">
            <Card.Body>
              <Card.Title className="text-center site-color-dark-red h2 mb-4 success">Thank You for Registering</Card.Title>
                          

              <div className="text-center">
                with<br />
                <h1 className='main-title'><img src={require('../../assets/images/satsang_logo.png')} /></h1>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default VerifyOtpSuccess
