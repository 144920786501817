import { Outlet } from "react-router-dom"
import Header from '../Header'
import Footer from '../Footer'

const Index = ({children}) => {
    return (
    <div className="main">
        <Header />
        <Outlet />
        <Footer />
    </div>
    )
}

export default Index