import React, { useState,useEffect } from 'react';
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Loader from '..//../components/Loader'
import { faEnvelope, faLock, faUser,faMobile } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../../actions/authActions';
import { signIn, signUp } from '../../api';

const Index = () => {
  const { t, i18n } = useTranslation();
  const [loading,setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false)
  const [resErr, setReserr] = useState({
    responseerror: ''
  })
  let initialData = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    errors: {},
  }
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    //console.log('from login page',formData);
    
    const { name, email, password, confirmPassword } = formData;
    const errors = {};

    // Validation rules
    if (!name.trim()) {
      errors.name = 'Name is required';
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }

    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }
    // set submmiting true
    setSubmitting(true)
    // If validation passes, proceed with registration logic
    // Example: Send registration data to server
    let response = await signUp(formData)
    if (!response.success) {
      setSubmitting(false);
      // console.log('response error', response)
      setReserr({ responseerror: response.message })
      return
    }
    console.log('signup success response', response)
    setSubmitting(false)
    setFormData(initialData)
    
  }
  const { name, email, password, confirmPassword, errors } = formData;

  useEffect(()=> {
    setTimeout(()=> {
      setLoading(false)
    },300)
  },[])
  if (loading) {
    return <Loader />
  }
  return (
    <div className='mainContainer'>
      <div className='innerBanner mb-5'>
        <img src={require('../../assets/images/banner/banner-1.JPG')} alt="Event" />
      <div className='innerBannerWrap non-event'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='banner-item-wrap'>
                <h1 className=''></h1>
                
                <p className='h3'><em>" बिना गुरु ज्ञान भटक रही दुनिया "</em></p>
              
                <h1 className=''>&nbsp;</h1>
                <p className='h3'><em>" The world is wandering without Guru Gyan "</em></p>
                
                <p className='h5'>~ Dheer Singh</p>
              </div> 
            </div>
            <div className='col-md-6'>
              <div className='banner-item-wrap'>
                
              </div> 
            </div>
          </div>          
        </div>
      </div>
    </div>
    <Container>
      <Row className="justify-content-md-center mb-5">
        {/* <Col xs={12} md={12}>
         <h1 className='site-color-dark-red text-center mb-5 mt-5'>Contact Us</h1>
        </Col> */}
        
        <Col xs={12} md={5}>
          <div className='about-image'>
          
            <img className='full-width' alt='image' src={require('../../assets/images/contact-image.JPG')} />
          
          </div>
        </Col>
        <Col xs={12} md={7}>
          <Card className="mt">
              <Card.Body>
              <h1 className='site-color-dark-red text-center mb-5 '>Contact Us</h1>
                <Form onSubmit={handleRegistration}>
                  <div className='flex gap-4'>
                  <Form.Group controlId="formBasicName" className="form-group">
                    <FontAwesomeIcon icon={faUser} className="form-icon" />
                    <Form.Control type="text" placeholder="Full Name" name="name" value={formData.name}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.name && <span className="error">{errors.name}</span>}
                  <Form.Group controlId="formBasicEmail" className="form-group">
                    <FontAwesomeIcon icon={faEnvelope} className="form-icon" />
                    <Form.Control type="email" placeholder="Email address" name="email" value={formData.email}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.email && <span className="error">{errors.email}</span>}
                  </div>
                  <Form.Group controlId="formBasicPassword" className="form-group">
                    <FontAwesomeIcon icon={faMobile} className="form-icon" />
                    <Form.Control type="text" placeholder="Mobile" name="mobile" value={formData.mobile}
                      onChange={handleInputChange} />
                  </Form.Group>
                  {errors.mobile && <span className="error">{errors.mobile}</span>}
                  <Form.Group controlId="formBasicConfirmPassword" className="form-group">
                    
                    <textarea name='message' placeholder='Message' value={formData.place} onChange={handleInputChange} className='long-content'>

                    </textarea>
                  </Form.Group>
                  {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                 
                    <Button variant="primary" type="submit" className="form-btn" >
                      Submit {submitting ? (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : null}
                    </Button>
                 
                </Form>

                
                {resErr.responseerror && <div className='text-center'><span className="error">{resErr.responseerror}</span></div>}

              </Card.Body>
            </Card>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Index
