import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { Link, useParams } from 'react-router-dom';
import { detailedhBhajan } from '../../api'



const BhajanInDetail = ({ navigations }) => {
    const bhajanId = useParams()
    console.log(bhajanId)
    const [documentData, setDocumentData] = useState(null);
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await detailedhBhajan(bhajanId.id);
                console.log('response bhanja in detail ', response)
                setDocumentData(response.result);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching document:', error);
                setLoading(false)
            }
        };

        fetchDocument();
    }, [bhajanId]);
    return (
        <>
           
            <div className='inner-wrapper'>
                <div className='container'>
                    <div className='top-heading flex justify-between'>
                        <h1 className='site-color-dark-red'>Bhajans </h1>

                    </div>

                    <div className=''>
                        {documentData && documentData.content.split('\n').map((line, index) => (
                            // Map each line to a separate <p> tag
                            <p key={index}>{line}</p>
                        ))}
                    </div>
                </div>
            </div>
         
        </>
    )
}

export default BhajanInDetail