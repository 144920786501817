import React , {useState}from 'react';
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate,Link, useParams, useLocation } from 'react-router-dom';
import { loginUser } from '../../actions/authActions';
import { signIn, verifySignup } from '../../api';
import { loginSuccess } from '../../store/authSlice';

const Verifyotp = ({navigation}) => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  //console.log('get data from registration screen',data)
  const dispatch = useDispatch()
  const [submitting,setSubmitting] = useState(false)
  const [err,setErr] = useState({
    message:null
  })
  let initialData = {
    otp: '',
    errors: {},
  }
  const [formData, setFormData] = useState({
    otp: '',
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };

  const handleRegistration = async(event) => {    
    //console.log('from login page',formData);
    event.preventDefault();
    const { otp } = formData;
    const errors = {};

    // Validation rules
    // Validation rules for OTP
    if (!otp) {
      errors.otp = 'Please enter OTP';
    } else if (!/^\d{6}$/.test(otp)) {
      errors.otp = 'OTP should be a 6-digit number';
    }

    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }
    // set submmiting true
    setSubmitting(true)
    // If validation passes, proceed with registration logic
    // Example: Send registration data to server
    let response = await verifySignup({token:formData.otp,verifyUserToken:data})
    if(!response.success) {
      setSubmitting(false);
      setFormData(initialData)
      console.log('response error',response)
      setErr({
        ...err,
        message: response.message
      })
      return
    }
    setSubmitting(false)
    
    dispatch(loginSuccess(response))
    setFormData(initialData)
    navigate('/verifysuccess')
    //console.log('Registration successful:', response);
  }
  const { otp, errors } = formData;
  return (
    <div className="auth-container-new mt-5">
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={4}>
          <Card className="mt-5">
            <Card.Body>
              <Card.Title className="text-center site-color-dark-red h2 mb-4">Verify OTP</Card.Title>
              <div className='text-center mb-4'>Please check your email and verify</div>
              <Form onSubmit={handleRegistration}>
                <Form.Group controlId="formBasicName" className="form-group">
                  <FontAwesomeIcon icon={faKey} className="form-icon" />
                  <Form.Control type="text" placeholder="Enter OTP"  name="otp" value={formData.otp}
          onChange={handleInputChange}/>
                </Form.Group>
                {errors.otp && <span className="error">{errors.otp}</span>}
                <div className="form-group">
                  <Button variant="primary" type="submit" className="form-btn" >
                    Verify {submitting ? (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : null}
                  </Button>
                </div>
              </Form>

              <div className="text-center mt-3">
              {err.message && <span className="error">{err.message}</span>}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Verifyotp
