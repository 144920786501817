

import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // You can replace with your state management library
import Cookies from 'js-cookie';
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './containers/Home'
import Login from './containers/Login'
import Layout from './components/Layout'
import Register from './containers/Register'
import Dashboard from './containers/AdminDashboard/Dashboard'
import Bhajans from './containers/Bhajans'
import About from './containers/About'
import Gallery from './containers/Gallery'
import Contact from './containers/Contact'
import Events from './containers/Events'
import BhajanInDetail from './containers/Bhajans/bhajanInDetail';
import VoiceRecognition from './containers/Examples/VoiceRecognition';
import ImageTextRecognition from './containers/Examples/ImageTextRecognition';
import Verifyotp from './containers/Register/verifyotp';
import VerifyOtpSuccess from './containers/Register/verifyOtpSuccess';
import Forgetpass from './containers/Login/forgetpass';
import ResetPassword from './containers/Login/resetPassword';
import NotFound from './containers/NotFound/404-notfound';
import MainHeader from './components/Header/MainHeader';
import CreateEvent from './containers/AdminDashboard/CreateEvent';
import EventDetail from './containers/Events/EventDetail';
import RequireAuth from './features/auth/RequireAuth';
import PersistLogin from './features/auth/PersistLogin';
import Prefetch from './features/auth/Prefetch';
import Welcome from './features/auth/Welcome';
import UsersList from './features/users/UsersList';
import EditUser from './features/users/EditUser';
import NewUserForm from './features/users/NewUserForm';
import { ROLES } from './config/roles'
import EventList from './features/events/EventList';
import EditEvent from './features/events/EditEvent';
import NewEvent from './features/events/NewEvent';
import BhajanList from './features/bhajans/BhajanList';
import NewBhajan from './features/bhajans/NewBhajan';
import EditBhajan from './features/bhajans/EditBhajan';
import FileUploadComponent from './features/events/FileUploadComponent';



const App = () => {

  // Get a cookie
  

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />

        {/* protected routes */}
        {/* <Route element={<RequireAuth />}>
          <Route path="welcome" element={<Welcome />} />
          <Route path="userslist" element={<UsersList />} />
        </Route> */}

    
        {/* public routes */}
        <Route index element={<About />} />
        <Route
          exact path="about"
          element={<About />}
        />
        <Route
          exact path="upload"
          element={<FileUploadComponent />}
        />
        <Route
          exact path="gallery"
          element={<Gallery />}
        />
        <Route
          exact path="contact"
          element={<Contact />}
        />
        <Route
          exact path="events"
          element={<Events />}
        />
        <Route
          exact path="events/:id"
          element={<EventDetail />}
        />
        <Route
          exact path="registration"
          element={<Register />}
        />

        <Route
          exact path="verifyregistration"
          element={<Verifyotp />}
        />
       <Route
          exact path="verifysuccess"
          element={<VerifyOtpSuccess />}
        />
       
        

        <Route exact path="forgotpassword"
          element={<Forgetpass /> } />

      <Route exact path="reset-password/:token"
          element={<ResetPassword /> } />

        {/* Protected route - Redirect to login if not authenticated */}
        

        {/* Other public or unprotected routes */}
        <Route
          exact path="bhajans"
          element={<Bhajans />}
        />
        <Route
          exact path="bhajans/:id"
          element={<BhajanInDetail />}
        />
        {/* <Route exact path="/about" element={<AboutPage />} /> */}
        
    
       
        <Route path="error" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/error" />} /> {/* Redirect all unknown routes to /error */}

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth  allowedRoles={[...Object.values(ROLES)]} />}>
            <Route element={<Prefetch />}>
              <Route path="dashboard" element={<Dashboard />}>

                <Route index element={<Welcome />} />

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<EditUser />} />
                    <Route path="new" element={<NewUserForm />} />
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path="events">
                    <Route index element={<EventList />} />
                    <Route path=":id" element={<EditEvent />} />
                    <Route path="new" element={<NewEvent />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path="bhajans">
                    <Route index element={<BhajanList />} />
                    <Route path=":id" element={<EditBhajan />} />
                    <Route path="new" element={<NewBhajan />} />
                  </Route>
                </Route>
               

              </Route>{/* End Dash */}
            </Route>
          </Route>
        </Route>
        

      </Route>
    </Routes>
  );
};

export default App;
