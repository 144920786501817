import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

const index = () => {
  return (
    <footer id='footer'>
      

      <div className='wrapper'>
        <div className='home'>
          <ul className='leftNavigation'>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            {/* <li><Link to="/members">Members</Link></li> */}
            <li><Link to="/bhajans">Bhajans</Link></li>
            <li><Link to="/events">Events</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
          <div className='footerBottom'>
          </div>
          <p>©Nirakarsatguru • All rights reserved</p>
        </div>
      </div>


    </footer>
  )
}

export default index
