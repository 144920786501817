import React from 'react';
import Header from '../../components/Header';
import Banner from '../../components/Banner'
import Footer from '../../components/Footer'
import './home.css'
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Index = () => {
  useDocumentTitle('Home')
  return (
    <div className='homepage-bg'>
      {/* <Header /> */}
      <Banner />



      <section className='top-content'>

        <div className='wrapper'>

          <div className='top-heading'>
            <h2 className='site-color-dark-red'>Adhyatma, the way of living</h2>
          </div>

          <div className='top-heading seperator'>
            <img alt='image' src={require('../../assets/images/floral-decor.png')} />
          </div>

          <div className='adhyatma'>

            <div className='top-section'>
              <div className='content'>
                <p>
                  तुलसी साथी विपत्ति के,… विद्या विनय विवेक ।
                  साहस सुकृति सुसत्यव्रत,… राम भरोसे एक ।।
                </p>
              </div>

              <div className='img-content'>
                <img alt='image' src={require('../../assets/images/tulsidas.jpg')} />
              </div>

            </div>

          </div>

        </div>

      </section>

      <section className='our-mission'>

        <div className='wrapper'>

          <div className='top-heading'>
            <h2 className='site-color-dark-red'>Our mission
            </h2>
          </div>

          <div className='our-mission-wrap'>

            <div className='item'>

              <img alt='image' src={require('../../assets/images/adhyatma.jpg')} />

              <h3>
                Adhyatma Darshan
              </h3>

              <p>
                Listen and follow the amritvaani.
              </p>

            </div>

            <div className='item'>

              <img alt='image' src={require('../../assets/images/adhyatma2.jpg')} />

              <h3>
                Practice Bhagavad Gita
              </h3>

              <p>
                Reading Bhagavad Gita and follow its path.
              </p>

            </div>

            <div className='item'>

              <img alt='image' src={require('../../assets/images/tulsidas.jpg')} />

              <h3>
                One for all
              </h3>

              <p>
                item1
              </p>

            </div>

          </div>

        </div>

      </section>


      <section className='testimonial'>
        <div className='wrapper'>

        </div>
      </section>

    </div>

  )
}

export default Index
