import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import {jwtDecode} from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isManager = false
    let isAdmin = false
    let status = "USER"
    console.log('AuthHook token',token)
    if (token) {
        const decoded = jwtDecode(token)
        const { id, role } = decoded

        isManager = role.includes('MANAGER')
        isAdmin = role.includes('ADMIN')

        if (isManager) status = "MANAGER"
        if (isAdmin) status = "ADMIN"
        console.log('ddddd=>',id, role, status, isManager, isAdmin)
        return { id, role, status, isManager, isAdmin }
    }

    return { id: '', role: [], isManager, isAdmin, status }
}
export default useAuth