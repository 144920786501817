import { useParams } from 'react-router-dom'
import EditEventForm from './EditEventForm'
import { useGetEventsQuery,useDeleteEventMutation } from './eventsApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'

import useTitle from '../../hooks/useTitle'
import { Spinner } from 'react-bootstrap'

const EditEvent = () => {
    useTitle('Nirakar Satguru: Edit Event')

    const { id } = useParams()
    console.log('params id',id)
    const { isManager, isAdmin } = useAuth()

    const { event } = useGetEventsQuery('eventList', {
        selectFromResult: ({ data }) => ({
            event: data?.entities[id]
        }),
    })
    //console.log(event)


    
    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })
    //console.log(users)
    if (!event || !users?.length) return <Spinner color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (event.id !== users.id) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditEventForm event={event} users={users} />

    return content
}
export default EditEvent