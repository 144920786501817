import axios from "axios";
import Cookies from "js-cookie";

const client = axios.create({
  baseURL: `https://nirakarsatguru.org/api/v1`,
});
const config = {
    headers: { "Content-Type": "application/json" },
};
const authConfig = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Cookies.get('authToken')}`, // Send cookie as Authorization header
  },
};

// Sign Up
export const signUp = async (data) => {
  try {
    const user = await client.post("/user/signup", {
        name: data.name,
        email: data.email,
        password: data.password
      },config)
    console.log('User signed up:', user);
    // Handle successful signup
    return user.data
  } catch (error) {
    console.error('Sign Up error:', error.response.data.message);
    // Handle signin error
    return error.response.data
  }
};

// Verify Sign Up
export const verifySignup = async (data) => {
  try {
    const user = await client.post("/user/verifyotp", {
        token: data.token,
        verifyUserToken:data.verifyUserToken
      },config)
    //console.log('Verify User:', user);
    // Handle successful signup
    return user.data
  } catch (error) {
    //console.error('Sign Up error:', error.response.data.message);
    // Handle signin error
    return error.response.data
  }
};


// Sign In
export const signIn = async (data) => {
  try {
    console.log('from api index file',data)
    const userCredential =  await client.post("/user/signin", {
        username: data.username,
        password: data.password
      },config)
    console.log('User signed in:', userCredential.data);
    // Handle successful signin
    return userCredential.data
  } catch (error) {
    console.error('Sign in error:', error.response.data.message);
    // Handle signin error
    return error.response.data
  }
};

// Sign Out
export const signOut = async () => {
  try {
    //await auth().signOut();
    console.log('User signed out');
    // Handle successful signout
  } catch (error) {
    console.error('Sign out error:', error);
    // Handle signout error
    return error.message
  }
};

// forgont password
export const forgotPassword = async (data) => {
  try {
    console.log('forgot passwrd',data)
    const userCredential =  await client.post("/user/forget-password", {
        email: data.email
      },config)
    console.log('User forgot email:', userCredential.data);
    // Handle successful signin
    return userCredential.data
  } catch (error) {
    console.error('Sign in error:', error.response.data.message);
    // Handle signin error
    return error.response.data
  }
};

// forgont password
export const resetPassword = async (data) => {
  try {
    console.log('reset passwrd',data)
    const userCredential =  await client.put(`/user/reset-password/${data.token}`, {
        password: data.password
      },config)
    console.log('User forgot email:', userCredential.data);
    // Handle successful signin
    return userCredential.data
  } catch (error) {
    console.error('Sign in error:', error.response.data.message);
    // Handle signin error
    return error.response.data
  }
};

// Create Bhajan
export const createBhajan = async (data) => {
    try {
      const bhajan =  await client.post("/bhajans/create-bhajan", {
          title: data.title,
          author: data.author,
          content: data.content
        },config)
      console.log('bhajan create successfuly:', bhajan.data);
      // Handle successful signin
      return bhajan.data.success
    } catch (error) {
      console.error('Sign in error:', error);
      // Handle signin error
      return error.message
    }
  };
  export const listBhajan = async (params) => {
    try {
      const bhajan =  await client.get("/all-bhajans",{params: params},config)
      console.log('get bhajan successfuly:', bhajan.data);
      // Handle successful signin
      return bhajan.data
    } catch (error) {
      console.error('Sign in error:', error);
      // Handle signin error
      return error.message
    }
  };

  export const searchBhajan = async (title) => {
    try {
      console.log('title',title)
      const bhajan =  await client.get("/bhajans",{params:{title}},config)
      console.log('seacrh bhajan successfuly:', bhajan.data);
      // Handle successful signin
      return bhajan.data
    } catch (error) {
      console.error('Sign in error:', error);
      // Handle signin error
      return error.message
    }
  };

  export const detailedhBhajan = async (id) => {
    try {
      console.log('id',id)
      const bhajan =  await client.get(`/bhajans/${id}`,{params:{id}},config)
      console.log('seacrh bhajan successfuly:', bhajan.data);
      // Handle successful signin
      return bhajan.data
    } catch (error) {
      console.error('Sign in error:', error);
      // Handle signin error
      return error.message
    }
  };

  // Create createAnEvent
export const createAnEvent = async (data) => {
  try {
    const event =  await client.post("/events/create-event", {
      name:data.name,
      banner:data.banner,
      date:data.date,
      time:data.time,
      organizer:data.organizer,
      place:data.place,
      category:data.category,
      },authConfig)
    console.log('Event create successfuly:', event.data);
    // Handle successful signin
    return event.data.success
  } catch (error) {
    console.error('Sign in error:', error);
    // Handle signin error
    return error.message
  }
};
export const allEvents = async (params) => {
  try {
    const events =  await client.get("/events",{params: params},config)
    console.log('get events successfuly:', events.data);
    // Handle successful signin
    return events.data
  } catch (error) {
    console.error('Events in error:', error);
    // Handle signin error
    return error.message
  }
};
export const getEventById = async (id) => {
  try {
    const event =  await client.get(`/events/${id}`,config)
    console.log('get events successfuly:', event.data);
    // Handle successful signin
    return event.data
  } catch (error) {
    console.error('Events in error:', error);
    // Handle signin error
    return error.message
  }
};