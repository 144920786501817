import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import classNames from 'classnames'
import './Header.css';
import {
  Link, matchPath, useLocation, useNavigate
} from "react-router-dom";
import { usePathname } from '../../hooks/useLocation';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { logout } from '../../store/authSlice';
import { logoutUser } from '../../actions/authActions';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useTranslation } from 'react-i18next';
import { useSendLogoutMutation } from '../../features/auth/authApiSlice'
import useAuth from '../../hooks/useAuth';

const Index = () => {
  const { isManager, isAdmin } = useAuth()

    const navigate = useNavigate()
  const [sendLogout, {
    isLoading,
    isSuccess,
    isError,
    error
}] = useSendLogoutMutation()
  const { t, i18n } = useTranslation();

  const  changeLanguage= (lang) =>{
    console.log('yess ',lang)
    const langg = i18n.changeLanguage(lang);
    console.log('languaerss',langg)
  }
  

  const [scrolled, setScrolled] = useState()
  const [pagename, setPageName] = useState('')
  const classes = classNames('header', {
    scrolled: scrolled,
  })
  const { pathname } = useLocation();
 
  useEffect(() => {
    const routes = ["/"];

    let path = routes.find((route) => matchPath(route, pathname));
    //console.log(path)
    const handleScroll = () => {

      if (window.pageYOffset > 711) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll);

   
    if (pathname != '/') {
      setPageName('innerpage')
    } else {
      setPageName('homepage')
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [pathname]);



  

useEffect(() => {
    if (isSuccess) navigate('/')
}, [isSuccess, navigate])

  

  return (

    <header id="header" className={scrolled ? `d-flex align-items-center setTodardk` : `d-flex align-items-center ${pagename}`}>






      <nav id="navbar" className="navbar container-fluid d-flex align-items-center">
        <div className='header-left flex'>
          <h1 className="logo"><Link to="/"><img src={require('../../assets/images/satsang_logo2.png')} /></Link></h1>
          <ul className=''>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/bhajans">Bhajans</Link></li>
            <li><Link to="/events">Events</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            {/* <li><Link to="/gallery">Gallery</Link></li> */}
          </ul>
        </div>

        {/* <ul className='rigtNavigation'>
          
            <>
              <li><Link to="/admin/dashboard">Dashboard</Link></li>
              <li><button  onClick={sendLogout}>Logout</button></li>
              <li className='user'><FontAwesomeIcon icon={faUser} /></li>
            </>
      
            <>
              <li><Link to="/registration" className='btn btn-primary'>Signup</Link></li>
              <li><Link to="/login" >Login</Link></li>
              {/* <li className='lang'>
                <DropdownButton id="dropdown-basic-button" title="EN">
                  <div className='lang-link' onClick={() => changeLanguage('hi')}>Hindi</div>
                  <div className='lang-link active' onClick={() => changeLanguage('en')}>English</div>
                </DropdownButton>  
              </li> 
            </>
       

        </ul> */}

      </nav>


    </header>

  )
}

export default Index
