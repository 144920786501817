import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser, faBook } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../hooks/useAuth'

const LeftNavigation = () => {
    const { id, isManager, isAdmin } = useAuth()
  return (
    <>
         <ul className="navigation">
            <li>
              <Link to="/dashboard/users">
                <FontAwesomeIcon icon={faUser} color='#ff0000' />
                <span>Users</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/events">
                <FontAwesomeIcon icon={faCalendar} />
                <span>Events</span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/bhajans">
                <FontAwesomeIcon icon={faBook} />
                <span>Bhajans</span>
              </Link>
            </li>
          </ul>
    </>
  )
}

export default LeftNavigation