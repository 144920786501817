import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Header from '../../components/Header'
import BannerInner from '../../components/Banner/BannerInner'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import Loader from '../../components/Loader'
import { allEvents } from '../../api'

const Index = () => {
    useDocumentTitle('Event Lists')
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [formData, setFormData] = useState({
        date: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Update currentPage based on user interaction
    };

    const searchItems = (e) => {
        e.preventDefault()
        console.log(formData.date)
        searchData(formData.date)
    }

    const searchData = async (date) => {
        
        try {
            let params = {
                page: currentPage1, // Specify the page number
                limit: 5, // Specify the limit per page
                date,
            }
            const response = await allEvents(params); // Replace with your API endpoint
            if (response.success) {

                setItems(response.result); // Set the fetched items in state
                setTotalPages(response.totalPages); // Assuming total pages is sent from the server
                setLoading(false);
                console.log('response in component', response)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        
        // Function to fetch data from the API endpoint
        const fetchData = async () => {
            try {
                let params = {
                    page: currentPage, // Specify the page number
                    limit: 5, // Specify the limit per page
                }
                const response = await allEvents(params); // Replace with your API endpoint
                if (response.success) {

                    setItems(response.result); // Set the fetched items in state
                    setTotalPages(response.totalPages); // Assuming total pages is sent from the server
                    setLoading(false);
                    console.log(response)
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData(); // Call the function to fetch data when the component mounts



    }, [currentPage]); // The empty dependency array ensures it only runs once
    if (loading) {
        return <Loader />
    }
    return (
        <div className='inner-wrapper'>

            {/* <BannerInner /> */}
            <>

                <div className='inner-wrapper'>
                    <div className='container'>
                        <div className='top-heading flex justify-between'>
                            <h1 className='site-color-dark-red'>Event List</h1>
                            <div className='search-wrap'>
                                <form onSubmit={searchItems}>
                                    <div className='flex search-box'>
                                        <input type='date' placeholder='Search Bahajan' className='' name="date" onChange={handleInputChange} value={formData.date} />
                                        <FontAwesomeIcon icon={faSearch} onClick={searchItems} size='xs' />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='listWrap'>
                            {loading ? (
                                <div className='loadering-box'><div className='loader-18'></div></div>
                            ) : (

                                <>
                                    {items.length > 0 ? (
                                        <ul className='item-list linked'>
                                            {items && items.map((item, index) => (
                                                <li key={index}>
                                                    <Link to={`/events/${item._id}`}><span>{item.name}</span><span>{item.date}</span></Link>                                                       
                                                </li>

                                            ))}
                                        </ul>
                                    ) : (<div className='no-data-found text-center'>No Events Found</div>)}
                                </>
                            )}

                        </div>
                        <div className='pagingwrap'>
                            <nav>
                                <ul className="pagination">
                                    <li className="page-item"><button className='page-link'
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button></li>
                                    <li className="page-item"><span className='page-link'>Page {currentPage} of {totalPages}</span></li>
                                    <li className="page-item"><button className='page-link'
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button></li>
                                </ul>
                            </nav>
                            {/* Pagination controls */}



                        </div>
                    </div>
                </div>

            </>

        </div>
    )
}

export default Index
