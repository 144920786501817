import { createSlice } from '@reduxjs/toolkit'

const bhajanSlice = createSlice({
    name: 'bhajans',
    initialState: { bhajan: null,pageNumber: 1 },
    reducers: {
        searchBhajan: (state, action) => {
            //const { token } = action.payload
            state.bhajan = action.payload
        },
        clearBhajan: (state, action) => {
            state.bhajan = null
        },
        changePage: (state, action) => {
            state.pageNumber = action.payload
        },
    }
})

export const { searchBhajan, clearBhajan,changePage } = bhajanSlice.actions

export default bhajanSlice.reducer
