import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header'
import BannerInner from '../../components/Banner/BannerInner'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import Loader from '../../components/Loader'
import { getEventById } from '../../api'

const EventDetail = () => {
  let { id } = useParams();
    //useDocumentTitle('id')
    const [item, setItem] = useState({
      banner:'',
      category:'',
      date:'',
      name:'',
      organizer:'',
      place:'',
      time:'',
    });
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
      try {
          
          const response = await getEventById(id); // Replace with your API endpoint
          if (response.success) {

              setItem(response.result); // Set the fetched items in state
              
              setLoading(false);
              console.log('response under useEffect',response.result, '', item.date)
          } else {
              throw new Error('Failed to fetch data');
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
      }
  };
    useEffect(()=> {
      // Function to fetch data from the API endpoint
      

      fetchData(); // Call the function to fetch data when the component mounts
  },[id])
    
    if(loading) {
        return <Loader />
    }
  return (
    <div className='inner-wrapper'>
        <BannerInner item={item} />
        <div className='container'>
            dddd
        </div>
    </div>
  )
}

export default EventDetail