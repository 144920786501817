import React, { useState,useRef,useEffect } from "react";
import { Container, Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
//import "./login.css";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../../actions/authActions';
import { setCredentials } from '../../features/auth/authSlice'
import { useLoginMutation } from '../../features/auth/authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'


const Index = () => {
  useTitle('User Login')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('')
  const [persist, setPersist] = usePersist()
  const { isAuthenticated, error, loading } = useSelector((state) => state.auth); // Get authentication state
  const [err, setErr] = useState({ error: false, message: null });
  const [submitting,setSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    errors: {}
  });
  const [login, { isLoading }] = useLoginMutation()
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: { ...formData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };
  const userRef = useRef()
    const errRef = useRef()

  const handleForgotPassword = () => {
    // Add your "Forgot Password" logic here
  };




  const subminHandler = async (e) => {
    e.preventDefault();

    const { username, password } = formData;
    const errors = {};

    // Validation rules
    

    if (!username.trim()) {
      errors.username = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(username)) {
      errors.username = 'Email is invalid';
    }

    if (!password) {
      errors.password = 'Password is required';
    } 


    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setFormData({ ...formData, errors });
      return;
    }
    setSubmitting(true)
    //console.log('from login page',formData)
    try {
      const { token } = await login({ username, password }).unwrap()
      console.log('accesstoken',token)
      dispatch(setCredentials({ token }))
      
      navigate('/dashboard')
  } catch (err) {
    console.log('err',err)
      if (!err.status) {
          setErrMsg('No Server Response');
      } else if (err.status === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.status === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg(err.data?.message);
      }
      errRef.current.focus();
      setSubmitting(false)
  }
  }





  const handleToggle = () => setPersist(prev => !prev)
  if (isAuthenticated) {
    navigate('/'); // Redirect if already authenticated
  }
  const {  errors } = formData;
  return (
    <div className="auth-container-new mt-5">


      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={4}>
            <Card className="mt-5 shadow">
              <Card.Body>
                <Card.Title className="text-center site-color-dark-red h2 mb-4">Login</Card.Title>
                <p ref={errRef} className={error} aria-live="assertive">{errMsg}</p>
                <Form onSubmit={subminHandler}>
                  <Form.Group controlId="formBasicEmail" className="form-group">
                    <FontAwesomeIcon icon={faEnvelope} className="form-icon" />
                    <Form.Control  type="email" placeholder="Enter email" name="username" value={formData.username}
                      onChange={handleInputChange} />

                  </Form.Group>
                  {errors.username && <span className="error">{errors.username}</span>}
                  <Form.Group controlId="formBasicPassword" className="form-group">
                    <FontAwesomeIcon icon={faLock} className="form-icon" />
                    <Form.Control type="password" name="password" placeholder="Password" value={formData.password}
                      onChange={handleInputChange} />

                  </Form.Group>
                  {errors.password && <span className="error">{errors.password}</span>}
                  <div className="form-group">
                    <Button variant="primary" type="submit" className="form-btn">
                      Login {submitting ? (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />) : null}
                    </Button>
                  </div>
                  
                  <label htmlFor="persist" className="form__persist ">
                        <input
                            type="checkbox"
                            className="form__checkbox"
                            id="persist"
                            onChange={handleToggle}
                            checked={persist}
                        />
                        Trust This Device
                    </label>


                </Form>
                <div className="link mt-4 text-center"  >
                  <Link to="/forgotpassword" >Forgot Password?</Link>

                </div>
                <div className="text-center mt-3">
                  <p>Don't have an account? <Link to="/registration">Sign Up</Link></p>
                </div>
                {error != null ? (<p className="error text-center">{error}</p>) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default Index;
