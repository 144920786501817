import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare ,faTrashCan} from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetBhajansQuery,useDeleteBhajanMutation } from './bhajansApiSlice'
import { memo } from 'react'

const Bhajan = ({ bhajanId,index }) => {

    const { bhajan } = useGetBhajansQuery('bhajanList',{
        selectFromResult: ({ data }) => ({
            bhajan: data?.entities[bhajanId]
        }),
    })
    const [deleteBhajan, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteBhajanMutation()

    const onDeleteBhajanClicked = async (bhajanId) => {
        await deleteBhajan({ id: bhajanId })
    }
    const navigate = useNavigate()

    if (bhajan) {
        const created = new Date(bhajan.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        //const date = new Date(bhajan.date).toLocaleString('en-US', { day: 'numeric', month: 'long' })

        const handleEdit = () => navigate(`/dashboard/bhajans/${bhajanId}`)

        return (
            <>
            <tr className="table__row">
                <td className="table__cell note__status">
                    <span className="note__status--completed">{index+1}</span>
                       
                </td>
                <td className="table__cell note__created">{bhajan.title}</td>
                <td className="table__cell note__created">{bhajan.author}</td>
                {/* <td className="table__cell note__created">{date}</td> */}
                {/* <td className="table__cell note__updated">{bhajan.time}</td> */}
                {/* <td className="table__cell note__title">{bhajan.place}</td> */}
                {/* <td className="table__cell note__username">{bhajan.banner}</td> */}

                <td className="table__cell">
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button
                        className="icon-button table__button"
                        onClick={()=>onDeleteBhajanClicked(bhajan.id)}
                    >
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </td>
            </tr>
            {isDelError?(<tr>
                <td colSpan={7}>{isDelError}</td>
            </tr>):null}
            </>
        )

    } else return null
}

const memoizedBhajan = memo(Bhajan)

export default memoizedBhajan