import { useState, useEffect } from "react"
import { useUpdateEventMutation, useDeleteEventMutation } from "./eventsApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { Form, Button,  Spinner } from 'react-bootstrap';
import useAuth from "../../hooks/useAuth"

const EditEventForm = ({ event, users }) => {

    const { isManager, isAdmin } = useAuth()

    const [updateEvent, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateEventMutation()

    const [deleteEvent, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteEventMutation()

    const navigate = useNavigate()

    const [title, setTitle] = useState(event.title)
    const [text, setText] = useState(event.text)
    const [completed, setCompleted] = useState(event.completed)
    const [userId, setUserId] = useState(event.user)
    const [success, setSuccess] = useState(false)
  const [err, setErr] = useState({ error: false, message: null });
  const [submitting, setSubmitting] = useState(false)
  const [eventData, setEventData] = useState({
    id:event.id,
    name: event.name,
    banner: event.banner,
    date: event.date,
    time: event.time, 
    organizer: event.organizer,
    place: event.place,
    category: event.category, // Define initial category (Daily, Weekly, Monthly, Yearly) as needed
    errors: {}
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
      errors: { ...eventData.errors, [name]: '' }, // Clear previous errors on input change
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    // Perform API call to send event data to the backend for creation
    // Use fetch or Axios to make API requests
    const { name, date, organizer, place, time, category, banner } = eventData;
    const errors = {};

    // Validation rules
    if (!name.trim()) {
      errors.name = 'Event Name is required';
    }
    if (!organizer.trim()) {
      errors.organizer = 'Organize Name is required';
    }
    if (!date.trim()) {
      errors.date = 'Event Date is required';
    }
    if (!time.trim()) {
      errors.time = 'Event time is required';
    }
    if (!category.trim()) {
      errors.category = 'Event type is required';
    }
    if (!place.trim()) {
      errors.place = 'Event place is required';
    }
    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setEventData({ ...eventData, errors });
      return;
    }
    setSubmitting(true)
    await updateEvent(eventData)
    
  };
  const successMessage = ()=> {
    return (
      <div className='text-center'>
        <h2 className="pageHeading success">Event created successfuly!</h2>
        <p>Go to <link to='/events'>Event List</link></p>
       
      </div>
    )
  }
  const { errors } = eventData
    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTitle('')
            setText('')
            setUserId('')
            navigate('/dashboard/events')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTitleChanged = e => setTitle(e.target.value)
    const onTextChanged = e => setText(e.target.value)
    const onCompletedChanged = e => setCompleted(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [title, text, userId].every(Boolean) && !isLoading

    const onSaveNoteClicked = async (e) => {
        if (canSave) {
            await updateEvent({ id: event.id, user: userId, title, text, completed })
        }
    }

    const onDeleteEventClicked = async () => {
        await deleteEvent({ id: event.id })
    }

    const created = new Date(event.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(event.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}

            > {user.username}</option >
        )
    })

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validTitleClass = !title ? "form__input--incomplete" : ''
    const validTextClass = !text ? "form__input--incomplete" : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <button
                className="icon-button"
                title="Delete"
                onClick={onDeleteEventClicked}
            >
                <FontAwesomeIcon icon={faTrashCan} />
            </button>
        )
    }

    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <Form onSubmit={handleSubmit}>
                  {/* Input fields for event creation */}
                  {/* Replace these with appropriate form controls */}
                  <div className='formGroup'>
                    <label>Event Name<sup>*</sup>:</label>
                    <input type='text' name='name' placeholder='Enter Event Name' value={eventData.name} onChange={handleInputChange} />
                    {errors.name && <span className="error">{errors.name}</span>}
                  </div>

                  <div className='formGroup'>
                    <label>Event Oraganizer<sup>*</sup>:</label>
                    <input type='text' name='organizer' value={eventData.organizer} onChange={handleInputChange} placeholder='Enter Organize' />
                    {errors.organizer && <span className="error">{errors.organizer}</span>}
                  </div>
                  <div className='flex gap-4'>
                  <div className='formGroup'>
                    <label>Event Date<sup>*</sup>:</label>
                    <input type='date' name='date' value={eventData.date} onChange={handleInputChange} placeholder='Enter date' />
                    {errors.date && <span className="error">{errors.date}</span>}
                  </div>
                  <div className='formGroup'>
                    <label>Event Time<sup>*</sup>:</label>
                    <input type='time' name='time' value={eventData.time} onChange={handleInputChange} placeholder='Enter time' />
                    {errors.time && <span className="error">{errors.time}</span>}
                  </div>
                  </div>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Event Banner:</Form.Label>
                    <Form.Control type="file" name='banner' value={eventData.banner} accept="image/*" onChange={handleInputChange} />
                  </Form.Group>
                  

                  <div className='formGroup'>
                    <label>Event Place<sup>*</sup>:</label>
                    <textarea name='place' placeholder='Enter Full address of the event ' value={eventData.place} onChange={handleInputChange} className='long-content'>

                    </textarea>
                    {errors.place && <span className="error">{errors.place}</span>}
                  </div>




                  {/* Add other input fields for date, time, duration, organizer, place, category */}

                  <Button variant="primary" type="submit">
                    Save Event {submitting ? (<Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />) : null}
                  </Button>
                </Form>
        </>
    )

    return content
}

export default EditEventForm